<template>
    <div  class="my-10 h-full">

        <div class="bg-indigo-50 rounded-md">
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 flex lg:items-center lg:justify-between">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                    <span class="block">A forum for all that matters</span>
                    <span class="block text-indigo-600">Ask. Discuss. Answer.</span>
                </h2>
                <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                    <div class="inline-flex rounded-md shadow">
                        <button @click="newPost" href="#" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                            Create a post
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white rounded-md p-2 mb-6 mt-10">
            <div>
                <label for="newPost" class="sr-only">New Post</label>
                <div class="relative rounded-md shadow-sm ">
                    <input type="text" @focus="newPost" id="newPost" class="block bg-gray-200 hover:bg-white border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 focus:bg-white border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Create New Post">
                </div>
            </div>
        </div>

<!--        <jiruto-row-loader v-if="true" class="w-full flex-1 rounded-md"></jiruto-row-loader>-->

        <div v-if="forum !== null">
            <div v-if="forum.posts === null || forum.posts === undefined || forum.posts.length === 0" class="bg-white rounded-md p-3">
                <div>
                    <p class="font-bold text-center text-3xl text-black">No posts to present</p>
                </div>
            </div>
        </div>


        <div v-if="forum !== null && forum.posts.length !== 0" class="rounded-md p-3">
            <div>
                <p class="font-bold text-3xl text-white">Latest Posts</p>
            </div>
        </div>

        <div v-if="forum !== null || isForumLoading" class="bg-white rounded-md overflow-hidden mt-2">
            <table class="min-w-full divide-y divide-gray-200 rounded-md">
                <jiruto-table-row-loader v-if="isForumLoading"></jiruto-table-row-loader>
                <tbody v-else class="divide-y divide-gray-200 rounded-md">
                <router-link tag="tr" :to="{name: 'forumPost', params: {postID: post.JID}}" v-for="(post) in forum.posts" :key="post.JID" class="cursor-pointer hover:bg-gray-100">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                        <div class="flex items-center" >
                            <div :id="`userCard${post.JID}`" class="mr-3">
                                <router-link tag="div" :to="`/${post.creator.username}`">
<!--                                    <img class="inline-block h-8 w-8 rounded-full" :src="post.creator.avatarUrl" alt="" @mouseenter="showTooltip(post.creator, `userCard${post.JID}`)" @mouseleave="hideTooltip">-->
                                    <profile-image-with-pop-card :prop-user="post.creator"></profile-image-with-pop-card>
                                </router-link>
                            </div>
                            <div>
                                {{ post.title }}
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 text-gray-500">
                        {{ timeAgo(post.createdAt) }}
                    </td>
                    <td class="px-3 py-4 whitespace-no-wrap text-left text-sm leading-5 font-medium">
                        <div v-if="post.commentsCount > 0" class="text-sm leading-5 text-jiruto-zotSecondary flex justify-start">
                            <div class="mr-1 text-jiruto-zotSecondary">
                                {{ post.commentsCount }}
                            </div>
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>
                        </div>

                    </td>
                </router-link>

                <!-- More rows... -->
                </tbody>
            </table>

        </div>




    </div>
</template>

<script>
    // import _ from 'lodash'
    // import tippy from 'tippy.js';
    // import 'tippy.js/dist/tippy.css'; // optional for styling
    import {timeAgo} from "@/utils/helpers";
    import * as axios from '@/axios-auth'
    import * as Popper from '@popperjs/core'
    // import UserPopCard from "../../../components/UserPopCard";
    import ProfileImageWithPopCard from "@/components/ProfileImageWithPopCard";
    // import JirutoRowLoader from "@/components/JirutoRowLoader";
    import JirutoTableRowLoader from "@/components/JirutoTableRowLoader";
    export default {
        name: "ForumPosts",
        components: {JirutoTableRowLoader, ProfileImageWithPopCard},
        props: ["communityID", "communityOwner", "communityName", "forumProp"],
        data(){
            return {
                isForumLoading: false,
                forum: null,
                userCardUser: null,
                popperInstance: null,
                currentRequest: null,
            }
        },
        created(){
            this.fetchForum()
        },
        watch: {
            forumProp(){
                this.fetchForum()
            }
        },
        methods: {
            fetchForum(){
                if(this.forumProp === null ){
                    return
                }
                // let loader = this.$loading.show({
                //     onCancel: this.loaderCanceled,
                // });
                this.isForumLoading = true
                axios.jigo.get(`/v2/community/integrations/forum/getForum/${this.forumProp.JID}`)
                .then((resp) => {
                    this.forum = resp.data
                    // loader.hide()
                    this.isForumLoading = false
                })
                .catch((err) => {
                    this.forum = {
                        posts: []
                    }
                    // loader.hide()
                    this.isForumLoading = false
                    console.error("ERORR: Fetching topics FAILED", err)
                })
            },
            showTooltip(user, postID){
                clearTimeout(this.currentRequest)

                this.currentRequest = setTimeout(() => {
                    this.userCardUser = user
                    const button = document.querySelector(`#${postID}`);
                    const tooltip = document.querySelector("#userPopCard");
                    console.log("tooltip in posts!", tooltip)
                    // const arrow = document.querySelector('#postsArrow');
                    tooltip.setAttribute('data-show', '');
                    this.popperInstance = Popper.createPopper(button, tooltip, {
                        placement: 'top',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 10],
                                },
                            },
                        ],
                    })
                }, 250)

            },
            destroyTooltip(){
                if (this.popperInstance) {
                    this.popperInstance.destroy();
                    this.popperInstance = null;
                }
            },
            hideTooltip(){
                clearTimeout(this.currentRequest)
                const tooltip = document.querySelector("#userPopCard");
                tooltip.removeAttribute('data-show');
                this.userCardUser = null;
                this.destroyTooltip()
            },
            newPost(){
                this.$router.push({name: "newPost"})
            },
            timeAgo,
        },
    }
</script>

<style scoped>

    /*#postsArrow,*/
    /*#postsArrow::before {*/
    /*    position: absolute;*/
    /*    width: 8px;*/
    /*    height: 8px;*/
    /*    z-index: 40;*/
    /*}*/

    /*#postsArrow::before {*/
    /*    @apply bg-purple-100 border-t shadow;*/
    /*    content: '';*/
    /*    transform: rotate(45deg);*/
    /*}*/
</style>
