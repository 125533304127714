<template>
    <tbody class="bg-white divide-y divide-gray-200">
        <tr class="animate-pulse">
            <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                        <div class="h-10 w-10 rounded-full bg-gray-300" alt="">
                        </div>
                    </div>
                    <div class="ml-4">
                        <div class=" bg-gray-300 h-4 w-40"></div>
                        <div class=" bg-gray-300 h-4 w-20 mt-2"></div>
                    </div>
                </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div class="bg-gray-300 h-10 w-80">
                    <div class="mt-1 ml-3 flex space-x-2 flex-wrap items-center bg-gray-300 h-10 ">
                    </div>
                </div>

            </td>
            <td class="pr-6">
                <div class="relative flex justify-end items-center h-10 w-5 bg-gray-300">

                </div>
            </td>
        </tr>

        <tr class="animate-pulse">
            <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                        <div class="h-10 w-10 rounded-full bg-gray-300" alt="">
                        </div>
                    </div>
                    <div class="ml-4">
                        <div class=" bg-gray-300 h-4 w-40"></div>
                        <div class=" bg-gray-300 h-4 w-20 mt-2"></div>
                    </div>
                </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div class="bg-gray-300 h-10 w-80">
                    <div class="mt-1 ml-3 flex space-x-2 flex-wrap items-center  bg-gray-300 h-10 ">
                    </div>
                </div>

            </td>
            <td class="pr-6">
                <div class="relative flex justify-end items-center h-10 w-5 bg-gray-300">

                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
export default {
name: "JirutoTableRowLoader"
}
</script>

<style scoped>

</style>
